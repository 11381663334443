import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  ContentContainer,
  PageHeading,
  StyledLink,
} from "../components/shared-styles"

const StyledHeadshot = styled.img`
  border-radius: 50%;
  width: 150px;
  margin: 0 auto;
  display: block;
  float: right;

  @media screen and (min-width: 768px) {
    width: 240px;
  }
`

const About = ({ data }) => {
  const {
    about: {
      childMarkdownRemark: { html },
    },
    image,
    image: {
      title,
      file: { url },
    },
  } = data.contentfulAbout

  return (
    <Layout>
      <SEO title={`About`} />
      <ContentContainer>
        {image && <StyledHeadshot alt={title} src={url} />}
        <PageHeading>{`About`}</PageHeading>
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></div>
        <StyledLink to="/blog">Blog</StyledLink>
      </ContentContainer>
    </Layout>
  )
}

export default About

export const query = graphql`
  query AboutPageQuery {
    contentfulAbout {
      image {
        file {
          url
        }
      }
      about {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
